<template>
<div class="container-fluid">
    <headerDesign name="Plan Management"
                  logo="https://adretreaver.com/wp-content/uploads/2021/03/AdRlogo-500.png"
                  button-show="false"
                  button-name="Client Management"
                  class=""></headerDesign>
    <div class="row p-2" style="background-color: #E8E8E8;">
        <div class="col-6 col-md-6 p-3 d-flex justify-content-center">
            <a href="" to="/clients" @click="$router.push({ path: '/clients' })" class="font-weight-bolder text-decoration-none btn btn-info" style="font-size: 17px; padding-top:20px;"><i class="fa fa-users"></i> ALL CLIENTS</a>
        </div>
        <div class="col-6 col-md-6 p-3 d-flex justify-content-center">
            <a href=""  @click="$router.push({ path: `/clients`, query: { edit: '1', id: clientId } })" class="font-weight-bolder text-decoration-none btn btn-info" style="font-size: 17px; height:70px; padding-top:20px;" ><i class="fa fa-user"></i> CLIENT MANAGEMENT</a>
        </div>
<!--        <div class="col-4 col-md-4 p-3 d-flex justify-content-center">-->
<!--            <a href="" to="/clients" @click="$router.push({ path: '/product/management', query: { client_id: client._id } })" class="font-weight-bolder text-decoration-none btn btn-info" style="font-size: 17px; padding-top:20px;" ><i class="fa fa-cube"></i> PRODUCT MANAGEMENT </a>-->
<!--        </div>-->
    </div>
    <manage v-if="manageModal" :id="$route.query.client_id" v-on:closedm="manageModal = false" />
    <div class="row">
        <div class="col-12 col-md-12">
            <b-table
                :fields="fields"
                :items="plans">

                <template v-slot:cell(PlanListing)="row">
                    <div v-if="row.item.PlanListing.NumberOfClicks"> {{ row.item.PlanListing.NumberOfClicks | convert }}</div>
                    <div v-else> {{ row.item.PlanListing.Bandwidth | convertG }}</div>
                </template>

                <template v-slot:cell(PlanListing.ProxyType)="row">
                    <div v-if="!row.item.PlanListing.ProxyType">{{ row.item.PlanListing.type|searchType }}</div>
                    <div v-else>{{ row.item.PlanListing.ProxyType }}</div>
                </template>

                <template v-slot:cell(Status)="row">
                    <b-form-checkbox v-model="row.item.Status" @change="switchStatus(row.item.PlanId, row.item.Status)" size="md" :value="'active'" :unchecked-value="'disabled'" button-variant="success" name="check-button" switch>
                        <b class="d-flex align-content-center text-white" :class="row.item.Status === 'active' ? 'text-success': 'text-danger'">{{ row.item.Status }}</b>
                    </b-form-checkbox>
                </template>

                <template v-slot:cell(Actions)="row">
                    
<!--                    <b-button class="btn btn-success" v-b-tooltip.hover.top title="View Product" size="sm" @click="$router.push({  })"><span class="fa fa-eye"></span></b-button>-->
                    <b-button v-if="row.item.ProductType !== 'contentcatcher'"  class="btn btn-info ml-1" v-b-tooltip.hover.top title="Edit Product" size="sm" @click="$router.push({ path: '/campaigns', query: { cp_id: row.item._id, client_id:clientId,pt: row.item.ProductType } })"><span class="fa fa-pencil"></span></b-button>
<!--                    <b-button v-if="row.item.ProductType === 'adverifier'" class="btn btn-info ml-1" v-b-tooltip.hover.top title="Edit Product" size="sm" @click="$router.push({ path: '/domain/management', query: { plan_id: row.item._id } })"><span class="fa fa-pencil"></span></b-button>-->
                    <b-button v-if="row.item.ProductType === 'contentcatcher'" class="btn btn-info ml-1" v-b-tooltip.hover.top title="Edit Product" size="sm" @click="$router.push({ path: '/workflow/configuration',  query: { plan_id: row.item._id, client_product_id: row.item._id, config_id: row.item.workflowConfig_id } })"><span class="fa fa-pencil"></span></b-button>
                    <b-button class="btn ml-1 btn-primary" v-b-tooltip.hover.top title="Rename Product" size="sm" @click="renamePlan(row.item)"><span class="fa fa-pencil-square-o"></span></b-button>

                    <b-button v-if="row.item.ProductType === 'contentcatcher'" class="ml-1" variant="primary" v-b-tooltip.hover.top title="View Results" size="sm" @click="$router.push({ path: '/workflow/results', query: { config_id: row.item.workflowConfig_id } })"><span class="fa fa-file-excel-o"></span></b-button>
                    <b-button class="btn btn-danger ml-1" v-b-tooltip.hover.top title="Delete Product" size="sm" @click="deletePlan( row.item._id, row.item.PlanId )"><span class="fa fa-trash"></span></b-button>
                </template>

            </b-table>
        </div>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col-3 col-md-3">
            <b-button class="btn btn-block" @click="$router.push({ path: '/adProducts', query: { client_id: $route.query.client_id } })">ADD PRODUCTS</b-button>
        </div>
    </div>

    <footerDesign  first-button="Add a Product" first-button-show="dark"  second-button="Manage Domains"
                   second-button-show="false" third-button-show="false" third-button="Manage Domains" class="mt-4">
    </footerDesign>
</div>
</template>

<script>
import footerDesign from "@/components/style_Component/footerDesign";
import headerDesign from "@/components/style_Component/headerDesign";
import Manage from "@/components/domain/management/manage";
import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
// import swal from "sweetalert";

export default {
    name: "productManagement",
    components: {
        footerDesign,
        headerDesign,
        Manage
    },
    data() {
        return {
            plans: [],
            manageModal: false,
            clientId:''
        }
    },
    created() {
        this.loadPlans()
        this.clientId = this.$route.query.client_id
    },
    computed: {
        fields() {
            return [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'ProductType',
                    label: 'Products',
                    sortable: true
                },
                {
                    key: 'created_ts',
                    label: 'Date',
                    sortable: true
                },
                {
                    key: 'PlanListing.ProxyType',
                    label: 'Plan Type',
                    sortable: true
                },
                {
                    key: 'PlanListing',
                    label: 'Plan Size',
                    sortable: true
                },
                {
                    key: 'PlanListing.price',
                    label: 'Price',
                    sortable: true
                },
                {
                    key: 'campaign_count',
                    label: '# Campaigns',
                    sortable: true
                },
                {
                    key: 'count',
                    label: '# Of Clicks Delivered (Today)',
                    sortable: true
                },
                {
                    key: 'Status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                    sortable: false
                }
            ]
        },
    },
    filters: {
        convert: function(value) {
            if (!value)
                return 'N/A'
            if (value < 1000000) {
                return value / 1000 + 'K'
            } else {
                return value / 1000000 + 'M'
            }
        },
        convertG: function(value) {
            if (!value)
                return 'N/A'

            return value / 1000000000 + 'G'
        },
    },
    methods: {
        loadPlans() {
            axios.get(`${this.$root.serverUrl}/admin/clients/plan/${this.$route.query.client_id}`).then((resp) => {
                //Store the stats
                this.plans = (resp.data && resp.data.data)? resp.data.data : [];
                
                this.$root.preloader = false;
            });
        },
        switchStatus(id, status) {
            this.plan_id = id
            if ( status === 'active' ) { this.enablePlan(id) }

            if ( status === 'disabled') { this.disablePlan(id) }
        },
        disablePlan(id) {
            axios.post(`${this.$root.serverUrl}/admin/clients/plan/disable/${this.$route.query.client_id}/${id}`).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    this.$bvToast.toast(resp.data.error.message, {
                        title: <strong>Warning: Plan status not modified.</strong>,
                        variant: 'danger',
                        solid: true
                    })
                } else {
                    this.$bvToast.toast(`Plan was successfully modified`, {
                        title: <strong>Success: Plan status modified.</strong>,
                        variant: 'success',
                        solid: true
                    })
                }
            }).catch((err) => {
                this.$bvToast.toast(err, {
                    title: <strong>Server Warning: Plan status not modified.</strong>,
                    variant: 'danger',
                    solid: true
                })
            })
        },
        enablePlan(id) {
            axios.post(`${this.$root.serverUrl}/admin/clients/plan/enable/${this.$route.query.client_id}/${id}`).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    this.$bvToast.toast(resp.data.error.message, {
                        title: <strong>Warning: Plan status not modified.</strong>,
                        variant: 'danger',
                        solid: true
                    })
                } else {
                    this.$bvToast.toast(`Plan was successfully modified`, {
                        title: <strong>Success: Plan status modified.</strong>,
                        variant: 'success',
                        solid: true
                    })
                }
            }).catch((err) => {
                this.$bvToast.toast(err, {
                    title: <strong>Server Warning: Plan status not modified.</strong>,
                    variant: 'danger',
                    solid: true
                })
            })
        },

        async renamePlan(plan) {
            const {value:name} = await Swal.fire({
                input: 'text',
                inputLabel: 'Desired name for product',
                inputPlaceholder: 'Name',
                showCancelButton: true,
                inputValidator: (name) => {
                    if (!name) {
                        return 'Please fill in name!'
                    }
                }
            })

            if(name) {
                await axios.post(`${this.$root.serverUrl}/admin/clients/plan/${plan._id}`, {name: name}).then(async (resp) => {
                    console.log(resp)
                    if (resp.data.error) {
                        swal({title: 'Error', text: resp.data.error.message, icon: 'error'}).then(() => {
                        })
                    } else {
                        swal({
                            title: 'Success',
                            text: 'Product name successfully changed',
                            icon: 'success'
                        }).then(() => {
                            location.reload()
                        })
                    }

                })
            }

        },
        deletePlan(_id, id) {
            swal({ text:"Do you want to delete this plan?", icon:"info", buttons:['No','Yes']}).then(function(val) {
                if (!val) return;

                if (val) {
                    this.plans.forEach((data) => {
                        if ( data.PlanId === id && data._id === _id ) {
                            if ( data.Status === 'active' ) {
                                swal({ title: "Error", text: "Cannot delete an active product.", icon: "error" })
                                return;
                            } else {
                                this.$root.preloader = true;

                                axios.delete(`${this.$root.serverUrl}/admin/clients/plan/${this.$route.query.client_id}/${id}/${_id}`).then(function (resp) {
                                    //Process the results
                                    if (resp.data && !resp.data.error) {
                                        //Saved successfully, navigate to Plans List
                                        this.loadPlans()
                                    } else {
                                        //Handle errors
                                        swal({title: "Error Removing Plan", text: resp.data.error.message, icon: "error"});
                                        this.$root.preloader = false;
                                    }
                                }.bind(this))
                                    .catch(function (err) {
                                        this.$root.preloader = false;
                                        this.errormsg = err.message || "An unexpected error occured";
                                        swal({title: "Error", text: this.errormsg, icon: "error"});
                                    }.bind(this))
                            }
                        }
                    })
                }
            }.bind(this));
        },
    }
}
</script>

<style scoped>
    footerDesign {
        position: fixed;
        bottom: 0;
    }
</style>
